<template style="height:100%">
  <div class="concent">
    <div class="team_title">
      <img src="@/assets/agent/img/team.jpg" />
    </div>
    <div class="tab_title"
         v-show="user.comid != 'NHWX'">
      <div @click="changeTab('TD')"
           :class="isSelect=='TD'?'select':'tab_team'" :style="isSelect=='TD'?{borderBottom:`2px solid ${getStorage('theme','')}`}:''">
        <span :style="isSelect=='TD'?{color:getStorage('theme','')}:''">我的团队({{tdnum}})</span>
      </div>
      <div @click="changeTab('TJ')"
           :class="isSelect=='TJ'?'select':'tab_team'" :style="isSelect=='TJ'?{borderBottom:`2px solid ${getStorage('theme','')}`}:''">
        <span :style="isSelect=='TJ'?{color:getStorage('theme','')}:''">我的推荐({{tjnum}})</span>
      </div>
      <div @click="changeTab('YC')"
           :class="isSelect=='YC'?'select':'tab_team'" :style="isSelect=='YC'?{borderBottom:`2px solid ${getStorage('theme','')}`}:''">
        <span :style="isSelect=='YC'?{color:getStorage('theme','')}:''">我的育成({{ycnum}})</span>
      </div>
    </div>
    <div class="nameNum">
      <div class="nameInside"
           v-show="num-0>0">
        <div class="name">
          <div>
            <img src="@/assets/agent/img/wotdxm.png"
                 class="iconImg" />
          </div>
          <div>
            <span>姓名</span>
          </div>
        </div>
        <div class="name">
          <div>
            <img src="@/assets/agent/img/wdtdgh.png"
                 class="iconImg" />
          </div>
          <div>
            <span>工号</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 团队 -->
    <div class="tree"
         v-if="isSelect=='TD'">
      <div class="nodata"
           v-show="isNull">
        <img class="kongimg"
             src="@/assets/image/null.png"
             alt="" />
      </div>
      <div v-for="(item,index) in list"
           :key="index"
           class="father"
           v-show="!isNull">
        <div class="item"
             @click="isShowChild(item)">
          <div class="icon">
            <md-icon name="arrow-right"
                     size="xs"
                     v-if="item.children && item.isShow"></md-icon>
            <md-icon name="arrow-up"
                     size="xs"
                     v-if="item.children && !item.isShow"></md-icon>
          </div>
          <div class="text">{{item.empname|getSub(14)}}</div>
          <div class="num">
            <span>{{item.empno}}</span><br />
            <span class="degreewords">{{item.degreename}}</span>
          </div>
        </div>

        <div v-if="item.children && item.isShow"
             class="children1">
          <div v-for="(item1,index1) in item.children"
               :key="index1">
            <div class="item"
                 @click="isShowChild(item1)">
              <div class="icon">
                <md-icon name="arrow-right"
                         size="xs"
                         v-if="item1.children && item1.isShow"></md-icon>
                <md-icon name="arrow-up"
                         size="xs"
                         v-if="item1.children && !item1.isShow"></md-icon>
              </div>
              <div class="text"> {{item1.empname|getSub(14)}}</div>
              <div class="num">
                <span>{{item1.empno}}</span><br />
                <span class="degreewords">{{ item1.degreename }}</span>
              </div>
            </div>

            <div v-if="item1.children && item1.isShow"
                 class="children2">
              <div v-for="(item2,index2) in item1.children"
                   :key="index2">
                <div class="item"
                     @click="isShowChild(item2)">
                  <div class="icon">
                    <md-icon name="arrow-right"
                             size="xs"
                             v-if="item2.children && item2.isShow"></md-icon>
                    <md-icon name="arrow-up"
                             size="xs"
                             v-if="item2.children && !item2.isShow"></md-icon>
                  </div>
                  <div class="text"> {{item2.empname|getSub(14)}}</div>
                  <div class="num">
                    <span>{{item2.empno}}</span><br />
                    <span class="degreewords">{{ item2.degreename }}</span>
                  </div>
                </div>

                <div v-if="item2.children && item2.isShow"
                     class="children3">
                  <div v-for="(item3,index3) in item2.children"
                       :key="index3">
                    <div class="item"
                         @click="isShowChild(item3)">
                      <div class="icon">
                        <md-icon name="arrow-right"
                                 size="xs"
                                 v-if="item3.children && item3.isShow"></md-icon>
                        <md-icon name="arrow-up"
                                 size="xs"
                                 v-if="item3.children && !item3.isShow"></md-icon>
                      </div>
                      <div class="text"> {{item3.empname|getSub(14)}}</div>
                      <div class="num">
                        <span>{{item3.empno}}</span><br />
                        <span class="degreewords">{{ item3.degreename }}</span>
                      </div>
                    </div>

                    <div v-if="item3.children && item3.isShow"
                         class="children4">
                      <div v-for="(item4,index4) in item3.children"
                           :key="index4">
                        <div class="item">
                          <div class="icon"></div>
                          <div class="text"> {{item4.empname|getSub(14)}}</div>
                          <div class="num">
                            <span>{{item4.empno}}</span>
                            <span class="degreewords">{{ item4.degreename }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐 -->
    <div v-if="isSelect!='TD'"
         class="tjyc">
      <div class="nodata"
           v-show="isNull">
        <img class="kongimg"
             src="@/assets/image/null.png"
             alt="" />
      </div>
      <div v-for="(item,index) in list"
           :key="index"
           class="tjyc_item"
           v-show="!isNull"
           @click="todetail(item.empno)">
        <div class="tjyc_left">
          <div class="empname">
            <span>{{item.empname}}</span><br />
            <span class="recommender"
                  v-if="!isnhwx">推荐人：{{item.name}}</span>
            <span class="recommender"
                  v-else>入司时间：{{item.indate}}</span>
          </div>
        </div>
        <div class="tjyc_right">
          <!-- <span>{{isSelect=='TJ'?item.tjempno:item.ycempno}}</span><br/> -->
          <div class="empno">
            <span>{{item.empno}}</span>
            <img src="@/assets/agent/img/wdtdsj.png"
                 class="iconImg clock" />
          </div>
          <div class="degreename">
            <span>{{item.degreename}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectRelation } from '@/api/agent/agent'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      user: '',
      aaa: '123',
      list: [],
      isSelect: 'TD',
      TD: [],
      TJ: [],
      YC: [],
      tdnum: 0,
      tjnum: 0,
      ycnum: 0,
      num: 1,
      isNull: true,
      isnhwx: false
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    if (this.user.comid == 'NHWX') {
      this.isnhwx = true
    }
    this.getData()
  },
  filters: {
    getSub (val, len) {
      if (val) {
        let str = ''
        val.length > len ? str = val.substr(0, len) + '...' : str = val
        return str
      } else {
        return ''
      }
    }
  },
  methods: {
    getData () {
      // Toast.loading('加载中...')
      selectRelation().then(res => {
        this.TD = this.addIsShow(res.data.data.tdInfo.relation)
        this.list = this.TD
        if (this.list.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.TJ = res.data.data.tjInfo.relation
        this.YC = res.data.data.ycInfo.relation
        this.tdnum = res.data.data.tdInfo.size
        this.tjnum = res.data.data.tjInfo.size
        this.ycnum = res.data.data.ycInfo.size
        if (this.user.comid == 'NHWX') {
          this.changeTab('TJ')
        }
        // Toast.hide()
      })
    },
    addIsShow (arr) {
      arr.forEach((item, index) => {
        this.$set(item, 'isShow', true)
        if (item.children && item.children.length > 0) {
          this.addIsShow(item.children)
        }
      })
      return arr
    },
    isShowChild (val) {
      val.isShow = !val.isShow
    },
    changeTab (val) {
      this.isSelect = val
      if (val == 'TD') {
        this.list = this.TD
        if (this.list.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.num = this.tdnum
      } else if (val == 'TJ') {
        this.list = this.TJ
        console.log(this.list.length)
        if (this.list.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.num = this.tjnum
      } else {
        this.list = this.YC
        if (this.list.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.num = this.ycnum
      }
    },
    todetail (empno) {
      this.$router.push(`/orderdetail?empno=${empno}`)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './index.styl';
</style>
